'use client';

import 'client-only';

import { t } from '@lingui/macro';
import { useApi } from '@prismo-io/core';
import { env } from '@prismo-io/env';
import type { ApiClient } from '@prismo-io/schemas';
import { type ReadonlyURLSearchParams, useSearchParams } from 'next/navigation';
import toast from 'react-hot-toast';
import useSWRMutation from 'swr/mutation';

type Options = {
  redirectTo?: string;
};

const fetcher = async (
  client: ApiClient,
  searchParams?: ReadonlyURLSearchParams | URLSearchParams,
  options: Options = {}
) => {
  const finalUrl = new URL(
    '/api/auth/linkedin',
    env.NEXT_PUBLIC_ONBOARDING_URL
  );

  if (searchParams) {
    searchParams.forEach((value, key) => {
      finalUrl.searchParams.append(key, value);
    });
  }

  if (options.redirectTo) {
    finalUrl.searchParams.set('redirectTo', options.redirectTo);
  }

  const { error, data: result } = await client.auth.signInWithOAuth({
    provider: 'linkedin_oidc',
    options: {
      redirectTo: finalUrl.toString(),
    },
  });

  if (error) {
    throw error;
  }

  if (!result) {
    return false;
  }

  return true;
};

export function useSignWithLinkedIn(options: Options = {}) {
  const { client } = useApi();

  const searchParams = useSearchParams();

  return useSWRMutation('sign-with-linkedIn', (key) => {
    return toast.promise(fetcher(client, searchParams, options), {
      loading: t`Connexion en cours...`,
      success: t`Vous êtes bien authentifié !`,
      error: t`Vos identifiants ne sont pas valides, veuillez les vérifier.`,
    });
  });
}
